import React from "react";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Tilgængelighedstest af kommunale hjemmesider" />
      <section>
        <h2>Velkommen</h2>

        <p>
          Vi har gennemført en test af de 98 kommuners web-tilgængelighed. Der er ikke tale om en
          fyldestgørende test, men derimod om stikprøver, og vi har derfor udvalgt tre sider på hver
          kommunes hjemmeside:
        </p>

        <ul>
          <li>Forsiden</li>
          <li>Folkeskole-sektionsside</li>
          <li>Renovation-sektionsside</li>
        </ul>

        <p>
          Der er tale om en automatiseret test op mod WCAG 2.1 AA standarden, som er den standard,
          der er lovmæssigt krav om at alle offentlige hjemmesider skal følge.
        </p>
        <p>Læs vores opsummerende artikel om undersøgelsen.</p>

        <p>
          Testen er udført af <a href="https://shiftcph.dk">Shift</a> i januar måned 2020. Testen er
          lavet vha. værktøjet <a href="https://pa11y.org/">pa11y</a>.
        </p>
      </section>
    </Layout>
  );
};

export default IndexPage;
